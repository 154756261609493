body {
  padding: 0;
  margin: 0;
}

input:-webkit-autofill {
  background-color: #232323 !important; /* Change this to your desired background color */
  -webkit-box-shadow: 0 0 0px 1000px #000000 inset !important;
  -webkit-text-fill-color: #ffffff !important;
  color: #ffffff !important; /* Ensure text color is appropriate */
}

/* For Firefox */
input:-moz-autofill {
  background-color: #232323 !important; /* Change this to your desired background color */
  -moz-text-fill-color: #ffffff !important;
  color: #ffffff !important; /* Ensure text color is appropriate */
}

/* For other browsers that support autofill */
input:autofill {
  background-color: #232323 !important; /* Change this to your desired background color */
  text-fill-color: #000 !important;
  color: #ffffff !important; /* Ensure text color is appropriate */
}

/* For WebKit browsers (e.g., Chrome, Safari) */
::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}

::-webkit-scrollbar-track {
  background: #232323; /* Dark gray track color */
}

::-webkit-scrollbar-thumb {
  background-color: #ffc800; /* Thumb color */
  border-radius: 10px; /* Rounded corners */
  border: 2px solid #232323; /* Optional: adds a border around the thumb */
}

/* For Firefox */
html {
  scrollbar-width: thin; /* Thin scrollbar */
  scrollbar-color: #ffc800 #232323; /* Thumb color and track color */
}
.image-gallery-content .image-gallery-slide .image-gallery-image {
  max-height: calc(75vh - 80px) !important;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.loader-inner-div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.loader-text {
  margin-right: 2%;
  font-weight: 500;
  font-size: 1.2rem;
}

.dot-elastic {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: black;
  color: black;
  animation: dotElastic 1s infinite linear;
}

.dot-elastic::before,
.dot-elastic::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-elastic::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: black;
  color: black;
  animation: dotElasticBefore 1s infinite linear;
}

.dot-elastic::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: black;
  color: black;
  animation: dotElasticAfter 1s infinite linear;
}

@keyframes dotElasticBefore {
  0% {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(1, 1.5);
  }
  50% {
    transform: scale(1, 0.67);
  }
  75% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}

@keyframes dotElastic {
  0% {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1, 1.5);
  }
  75% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}

@keyframes dotElasticAfter {
  0% {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1, 0.67);
  }
  75% {
    transform: scale(1, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}
select::-ms-expand {
  display: block;
}
/* .table-cell{
font-size: 5px;
} */
h4 {
  font-size: 20px !important;
}
h6 {
  font-size: 14px !important;
}
.css-1qi8750-MuiButtonBase-root-MuiTab-root {
  color: #ffffff;
  border-bottom: 1px solid #2c2c2c;
}
.MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-regular.MuiTablePagination-toolbar.css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar {
  color: #ffc800;
}
.css-j11maj-MuiTableCell-root {
  border-bottom: 1px solid rgb(41 42 42);
}
.css-xnfavz-MuiTableCell-root {
  border-bottom: 1px solid rgb(41 42 42);
}
.css-1qi8750-MuiButtonBase-root-MuiTab-root.Mui-selected {
  /*   color: #FFC800; */
  color: #ffffff;
  font-size: 13px;
}
.accordionCustom {
  display: inline;
  align-items: center;
  justify-content: center;
  text-align: left;
}

/* below css is for date picker filter in the table  */
.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.css-y6d1ek-MuiInputBase-root-MuiOutlinedInput-root {
  height: 40px;
  margin: 5px 0 0 0;
}
/* below css for transaction table */
td.MuiTableCell-root.MuiTableCell-body.MuiTableCell-sizeSmall.css-1achpeu-MuiTableCell-root {
  border-bottom: 1px solid #333333;
}
tr.MuiTableRow-root.MuiTableRow-head.css-egi9vb-MuiTableRow-root {
  border-bottom: 1px solid #333333;
}
